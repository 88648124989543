import React, {Fragment} from 'react'

export default class ScrollFix extends React.Component {

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll(e) {
    if(this.ref) {
      this.ref.style.top = window.scrollY + this.props.offset + 'px'
    }
  }

  render() {
    // const { fixed } = this.state 
    return (
      <div ref={r => { this.ref = r }} style={this.props.style} >
        {this.props.children}
      </div>
    )
  }

}